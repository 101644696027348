$base-color: #9e1726;

.content-product {
  padding: 100px 0 0;

  .header-popular {
    .title {
      font-size: 31px;
      font-weight: 500;
      margin: 0;
    }

    .subtitle {
      font-weight: 300;
      margin-bottom: 30px;
    }
  }

  .card-product {
    margin-bottom: 15px;

    .card-header {
      background-size: cover;
      background-position: center;
      height: 200px;
    }

    .card-product-detail {
      .card-product-text {
        .title {
          font-weight: 500;
          font-size: 18px;
        }

        .price {
          color: #898a8d;

          span {
            color: #ffd200;
            font-weight: 500;
            margin-right: 10px;

            svg {
              margin-top: -5px;
            }
          }
        }
      }

      .card-product-category-block {
        margin: 0;

        .card-product-category {
          background-color: #d1311f47;
          color: $base-color;
          font-weight: normal;
          margin: 5px 5px 5px 0;
        }
      }

      .marker {
        color: $base-color;
        font-size: 18px;

        svg {
          margin-top: -3px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content-product {
    .header-product {
      .title {
        text-align: center;
        font-size: 27px;
      }
  
      .subtitle {
        text-align: center;
      }
    }

    .button-product {
      text-align: center;
      margin-bottom: 25px;
    }
  }
}