$base-color: #9e1726;
$base-button-color: #bf3c4b;
$base-text-color: #07222c;

.btn-base {
  border: var(--bs-btn-border-width) solid $base-button-color !important;
  background-color: $base-button-color !important;
  font-weight: 600;
  padding: 10px 25px;
}

.app-header {
  background-color: #ffffff;
  border-color: 1px solid #0000001f;

  .app-button {
    padding: 8px 16px !important;
    font-weight: 500;
    color: #07222c;
  }

  .btn-back {
    text-decoration: none;
    font-weight: 500;
    color: $base-color;
  }
}

.app-header-line {
  box-shadow: 0 0 3px #9e1726;
}

.app-tbl-center {
  height: 100%;
  width: 100%;

  td {
    vertical-align: middle;
  }

  &.at-mobile{
    text-align: center;

    td {
      padding: 115px 25px 65px;
    }
  }
}

.footer-block {
  padding: 50px 0 25px;
  background-color: #283951;
  color: #f9fafc;

  p {
    padding: 15px 0;
    margin: 0;
  }

  .footer-menu-title {
    font-weight: 500;
    font-size: 21px;
    margin: 0 0 10px;
  }

  .footer-menu-list {
    padding: 3px 0;
  }

  .copyright {
    padding-top: 75px;
    font-size: 14px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .footer-block  {
    .footer-menu-title {
      margin: 25px 0 0;
    }
  }
}