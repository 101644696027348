$base-color: #9e1726;

.content-category {
  padding: 75px calc(var(--bs-gutter-x) * .5);

  .title {
    font-size: 31px;
    font-weight: 500;
    line-height: 1.25
  }

  .subtitle {
    font-size: 16px;
    padding-left: 1px;
    margin-bottom: 25px;
  }

  .card-category {
    margin-bottom: 25px;

    img {
      width: 100%;
    }

    .card-text {
      padding: 5px;
    }
  }
}

@media (max-width: 992px) {
  .content-category {
    .title {
      text-align: center;
      font-size: 27px;
    }

    .subtitle {
      text-align: center;
    }
  }
}