$base-color: #9e1726;

.content-popular {
  padding: 75px 0 0;

  .header-popular {
    .title {
      font-size: 31px;
      font-weight: 500;
      margin: 0;
    }

    .subtitle {
      font-weight: 300;
      margin-bottom: 30px;
    }
  }

  .button-popular {
    text-align: right;
  }

  .card-popular {
    margin-bottom: 15px;

    .card-header {
      color: #ffffff;
      padding: 0;
      background-size: cover;
      background-position: center;
      height: 250px;

      .card-popular-title {
        padding: 15px;
        background-color: rgb(0 0 0 / 63%);
        border-top-left-radius: var(--bs-card-border-radius);
        border-top-right-radius: var(--bs-card-border-radius);

        img {
          width: 75px;
          height: 75px;
          float: left;
        }

        .card-popular-text {
          padding: 10px 15px 10px 90px;

          .title {
            font-weight: 500;
            font-size: 19px;
          }
        }
      }
    }

    .card-popular-detail {
      .text-bg-danger {
        margin-left: 5px;

        span {
          font-weight: normal;
        }
      }

      .list-group {
        .list-group-item {
          padding: 15px 0;

          .item-popular {
            width: 75px;
            float: left;
          }

          .item-popular-text {
            padding-left: 90px;

            h5 {
              overflow: hidden;
              width: auto;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 18px;
            }

            span {
              font-weight: 600;
              font-size: 18px;
              min-width: 110px;
              text-align: right;

              svg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content-popular {
    .header-popular {
      .title {
        text-align: center;
        font-size: 27px;
      }
  
      .subtitle {
        text-align: center;
      }
    }

    .button-popular {
      text-align: center;
      margin-bottom: 25px;
    }
  }
}