$base-color: #9e1726;

.information-block {
  background-position: center;
  background-color: $base-color;

  .card-information {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 25px;

    .phone-image {
      width: 50%;
    }

    .title {
      font-size: 32px;
      font-weight: 600;
      color: #f9fafc;
    }

    .subtitle {
      padding-left: 2px;
      font-size: 18px;
      margin: 10px 0 25px;
      color: #f9fafc;
    }

    .download {
      a {
        margin-right: 15px;
      }
    }
  }

  table {
    margin-left: auto;

    td {
      text-align: left;
    }
  }
}

@media (max-width: 992px) {
  .information-block {
    .card-information {
      table {
        width: 100%;
      }

      .title {
        text-align: center;
        font-size: 27px;
      }
  
      .subtitle {
        text-align: center;
      }

      .download {
        text-align: center;

        a {
          margin: 0;

          img {
            width: 155px;
          }
        }
      }
    }
  }
}