$base-color: #9e1726;

.content-guideline {
  padding-top: 50px;

  .image-guideline {
    width: 100%;
  }

  .table-guideline {
    width: 100%;
    height: 100%;

    .title-guideline {
      font-weight: 600;
      margin: 0;
    }

    .detail-guideline {
      font-weight: 300;
      margin-bottom: 30px;
    }

    .number-guideline {
      height: 40px;
      width: 40px;
      background-color: $base-color;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
      padding: 8px 0;
      border-radius: 50%;
      float: left;
      margin: 5px;
    }

    .text-guideline {
      padding-left: 55px;
      padding-bottom: 15px;

      .title {
        font-size: 18px;
        font-weight: 500;
      }

      .detail {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 992px) {
  .content-guideline {
    .table-guideline {
      .title-guideline {
        text-align: center;
        font-size: 27px;
      }
  
      .detail-guideline {
        text-align: center;
        font-size: 18px;
      }
    }
  }
}