$base-color: #9e1726;

.header-banner {
  background-position: center;
  background-color: $base-color;
  color: #ffffff;

  .table-banner {
    width: 100%;

    .left-banner {
      width: 75%;

      .title {
        margin-top: 35px;
        width: 450px;
        font-weight: 600;
      }
  
      .subtitle {
        font-size: 16px;
        padding: 15px 0;
        width: 565px;
        font-weight: 400;
      }

      .download {
        a {
          margin-right: 5px;
        }
      }
    }

    .right-banner {
      width: 25%;

      img {
        height: 30em;
        box-shadow: 0 0 3px #0000004a;
      }
    }

    .mobile-banner {
      text-align: center;
      padding: 150px 0 100px;

      .content-box {
        padding: 0 20px;

        .title {
          font-weight: 600;
        }
  
        .subtitle {
          font-size: 16px;
          padding: 15px 0;
          font-weight: 400;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .header-banner {
    .on-desktop {
      display: block;
    }

    .on-mobile {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .header-banner {
    .on-desktop {
      display: none;
    }

    .on-mobile {
      display: block;
    }
  }
}