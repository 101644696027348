$base-color: #9e1726;

.content-service {
  padding: 50px 0;

  .header-service {
    padding: 25px 15px;
    text-align: center;
    
    h5, h1 {
      line-height: 1.2;
      margin: 0;
    }

    .indicator {
      color: $base-color;
    }

    .title {
      margin-top: 20px;
      font-weight: 600;
      font-size: 35px;
    }

    .subtitle {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .card-service {
    padding: 15px;
    margin-bottom: 25px;
    border: 2px solid $base-color;
    border-radius: 10px;

    .card-image {
      width: 50px;
      float: left;
    }

    .card-text {
      padding-left: 65px;
      text-align: left;

      .title {
        font-weight: 500;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: auto;
      }

      .content {
        font-size: 16px;
        font-weight: 300;
      }
    }

    &.reverse {
      background-color: $base-color;
      color: #fff;
    }
  }
}

@media (max-width: 992px) {
  .content-service {
    .header-service {
      .title {
        font-size: 27px !important;
        margin-bottom: 5px;
      }

      .subtitle {
        font-size: 17px;
      }
    }
  }
}